import React from "react";
import './LogoModal.css';

export default function LogoModal() {
  return (
          <svg
            className="logo-image"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 200 1920 1080"
            style={{ enableBackground: "new 0 0 1920 1080" }}
            xmlSpace="preserve"
          >
            <style
              type="text/css"
              dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:#41140F;}\n" }}
            />
            <g id="Layer_2">
              <g>
                <path
                  id="Logo"
                  className="draw st0"
                  d="M235.36,346.59c29.98,0,52.16,9.29,72.54,27.88c2.7,2.4,2.7,6,0.3,8.39l-23.38,24.28c-2.1,2.1-5.4,2.1-7.49,0
			c-12.29-10.79-25.78-15.29-40.47-15.29c-33.87,0-58.75,28.18-58.75,61.75c0,33.27,25.18,60.85,59.05,60.85
			c16.49,0,29.38-5.1,40.17-14.39c2.1-1.8,6-1.8,7.79,0.3l23.38,24.88c2.1,2.1,1.8,6-0.3,8.09c-20.38,19.78-46.16,29.08-72.84,29.08
			c-59.95,0-108.21-47.66-108.21-107.61C127.15,394.85,175.41,346.59,235.36,346.59z"
                />
                <path
                  id="Logo"
                  className="draw st0"
                  d="M385.54,458.4L319.3,358.28c-2.4-3.9,0-8.69,4.8-8.69h38.97c2.4,0,3.9,1.5,4.8,2.7l41.67,61.45l41.67-61.45
			c0.9-1.2,2.1-2.7,4.8-2.7h38.97c4.8,0,7.19,4.8,4.8,8.69l-67.15,99.82v95.62c0,3-2.7,5.7-5.7,5.7h-35.67c-3.3,0-5.7-2.7-5.7-5.7
			V458.4z"
                />
                <path
                  id="Logo"
                  className="draw st0"
                  d="M628.95,452.71c18.29,6,39.57,19.78,39.57,48.86c0,35.37-29.68,57.85-65.35,57.85h-74.34
			c-3.3,0-5.7-2.7-5.7-5.7V355.28c0-3,2.4-5.7,5.7-5.7h69.84c35.37,0,64.45,24.28,64.45,55.75c0,23.98-17.09,40.17-34.17,47.06
			V452.71z M594.78,434.72c11.39,0,19.48-9.59,19.48-21.58s-8.09-20.38-19.48-20.38H569.9v41.97H594.78z M595.38,516.55
			c14.99,0,23.38-7.49,23.38-21.58c0-11.99-8.09-21.28-22.78-21.28H569.9v42.87H595.38z"
                />
                <path
                  id="Logo"
                  className="draw st0"
                  d="M705.99,355.28c0-3,2.4-5.7,5.7-5.7h124.1c3.3,0,5.7,2.7,5.7,5.7v32.67c0,3-2.4,5.7-5.7,5.7h-83.03v37.17
			h68.35c3,0,5.7,2.7,5.7,5.7v32.67c0,3.3-2.7,5.7-5.7,5.7h-68.35v40.47h83.03c3.3,0,5.7,2.7,5.7,5.7v32.67c0,3-2.4,5.7-5.7,5.7
			h-124.1c-3.3,0-5.7-2.7-5.7-5.7V355.28z"
                />
                <path
                  id="Logo"
                  className="draw st0"
                  d="M879.25,355.28c0-3,2.4-5.7,5.7-5.7h89.63c38.07,0,65.35,29.08,65.35,64.75c0,27.58-16.49,49.76-44.36,60.25
			l41.07,76.14c2.1,3.9,0,8.69-5.1,8.69h-39.87c-2.4,0-4.2-1.5-4.8-2.7l-39.87-79.44h-20.68v76.44c0,3-2.7,5.7-5.7,5.7h-35.67
			c-3.3,0-5.7-2.7-5.7-5.7V355.28z M970.67,439.22c12.29,0,22.78-9.89,22.78-23.98c0-14.09-10.49-22.78-22.78-22.78h-44.36v46.76
			H970.67z"
                />
                <path
                  id="Logo"
                  className="draw st0"
                  d="M1069.89,539.34c-2.4-2.4-3.3-5.1-1.2-8.99l13.79-24.28c2.4-3.9,7.19-3.9,10.19-1.8
			c8.39,5.7,23.68,16.49,42.57,16.49c11.99,0,20.68-7.49,20.68-17.99c0-12.59-11.09-20.68-30.88-30.28
			c-29.08-14.09-58.15-30.28-58.15-66.25c0-29.68,23.08-59.65,69.24-59.65c31.17,0,55.16,15.89,64.15,22.48c3.6,2.1,3,7.49,1.8,9.59
			l-15.29,23.08c-2.1,3.3-7.19,5.7-13.19,2.1c-6.89-4.8-24.58-16.19-40.47-16.19c-12.59,0-18.29,7.79-18.29,16.49
			c0,9.89,11.69,17.69,32.67,27.88c26.38,12.89,60.55,27.58,60.55,68.34c0,30.88-26.68,62.05-70.74,62.05
			C1098.37,562.42,1076.79,546.23,1069.89,539.34z"
                />
                <path
                  id="Logo"
                  className="draw st0"
                  d="M1339.08,346.59c59.95,0,107.91,48.26,107.91,108.21c0,59.95-47.96,107.61-107.91,107.61
			c-59.95,0-107.61-47.66-107.61-107.61C1231.46,394.85,1279.13,346.59,1339.08,346.59z M1339.08,514.46
			c32.97,0,59.95-26.98,59.95-59.65c0-32.97-26.98-60.25-59.95-60.25c-32.67,0-59.65,27.28-59.65,60.25
			C1279.43,487.48,1306.4,514.46,1339.08,514.46z"
                />
                <path
                  id="Logo"
                  className="draw st0"
                  d="M1482.37,355.28c0-3,2.4-5.7,5.7-5.7h124.1c3.3,0,5.7,2.7,5.7,5.7v32.67c0,3-2.4,5.7-5.7,5.7h-83.03v43.17
			h68.34c3,0,5.7,2.7,5.7,5.7v32.67c0,3-2.7,5.7-5.7,5.7h-68.34v72.84c0,3-2.7,5.7-5.7,5.7h-35.37c-3.3,0-5.7-2.7-5.7-5.7V355.28z"
                />
                <path
                  id="Logo"
                  className="draw st0"
                  d="M1681.71,393.65h-42.87c-3.3,0-5.7-2.7-5.7-5.7v-32.67c0-3,2.4-5.7,5.7-5.7h133.09c3.3,0,5.7,2.7,5.7,5.7
			v32.67c0,3-2.4,5.7-5.7,5.7h-42.87v160.07c0,3-2.7,5.7-5.7,5.7h-35.97c-3,0-5.7-2.7-5.7-5.7V393.65z"
                />
              </g>
              <g>
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M121.91,662.11v-33.7h22.3c4.41,0,8.71,0.5,12.92,1.51c4.71,1.11,8.69,2.71,11.96,4.79c11.6,7.4,17.4,18.11,17.4,32.14
			c0,13.62-5.8,24.2-17.4,31.73c-3.26,2.09-7.26,3.7-12.01,4.84c-4.2,0.98-8.49,1.46-12.86,1.46h-22.3v-35.87h-6.15v-6.91H121.91z
			 M129.53,669.02v28.96h12.71c2.52,0,4.71-0.07,6.56-0.2c2.29-0.17,4.37-0.45,6.26-0.86c4.41-0.94,8.32-2.66,11.75-5.15
			c3.83-2.76,6.83-6.49,8.98-11.2c2.02-4.44,3.03-9.11,3.03-14.02c0-10.9-4.34-19.34-13.02-25.33c-3.43-2.39-7.45-4.02-12.06-4.89
			c-1.95-0.37-4.12-0.64-6.51-0.81c-1.85-0.1-4.07-0.15-6.66-0.15h-11.05v26.74h23.21v6.91H129.53z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M258.08,704.89l-11.1-25.73h-35.52l-11.15,25.73h-7.97l32.64-76.48h8.58l32.84,76.48H258.08z M229.32,637.14l-14.98,35.11
			H244L229.32,637.14z M237.39,624.07l-20.48-11.05l3.33-6.21l20.58,11.3L237.39,624.07z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M340.81,694.75c-7.94,7.67-17.4,11.5-28.4,11.5c-10.83,0-20.25-3.8-28.25-11.4c-8.11-7.74-12.16-17-12.16-27.8
			c0-11.1,3.95-20.55,11.86-28.35c7.87-7.74,17.39-11.6,28.55-11.6c11.03,0,20.52,3.83,28.45,11.5
			c8.04,7.74,12.06,17.08,12.06,28.05C352.92,677.61,348.88,686.98,340.81,694.75z M335.56,643.64c-6.46-6.42-14.18-9.64-23.16-9.64
			c-9.08,0-16.82,3.25-23.21,9.74c-6.36,6.49-9.54,14.26-9.54,23.31c0,8.81,3.26,16.4,9.79,22.75c6.46,6.32,14.11,9.48,22.95,9.48
			c8.95,0,16.65-3.18,23.11-9.54c6.49-6.36,9.74-14.02,9.74-23C345.25,657.77,342.02,650.07,335.56,643.64z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M411.74,635.37v69.52h-7.67v-69.52h-17.56v-6.96h42.68v6.96H411.74z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M496.85,704.89l-11.1-25.73h-35.52l-11.15,25.73h-7.97l32.64-76.48h8.58l32.84,76.48H496.85z M468.09,637.14l-14.98,35.11
			h29.66L468.09,637.14z M464.31,724.31v-13.07h7.67v13.07H464.31z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M579.58,694.75c-7.94,7.67-17.4,11.5-28.4,11.5c-10.83,0-20.25-3.8-28.25-11.4c-8.11-7.74-12.16-17-12.16-27.8
			c0-11.1,3.95-20.55,11.86-28.35c7.87-7.74,17.39-11.6,28.55-11.6c11.03,0,20.52,3.83,28.45,11.5
			c8.04,7.74,12.06,17.08,12.06,28.05C591.69,677.61,587.66,686.98,579.58,694.75z M574.34,643.64c-6.46-6.42-14.18-9.64-23.16-9.64
			c-9.08,0-16.82,3.25-23.21,9.74c-6.36,6.49-9.54,14.26-9.54,23.31c0,8.81,3.26,16.4,9.79,22.75c6.46,6.32,14.11,9.48,22.95,9.48
			c8.95,0,16.65-3.18,23.11-9.54c6.49-6.36,9.74-14.02,9.74-23C584.02,657.77,580.79,650.07,574.34,643.64z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M704.34,684.86c-7.77,14.26-19.51,21.39-35.21,21.39c-11.03,0-20.45-3.82-28.25-11.45c-7.84-7.7-11.75-17.05-11.75-28.05
			c0-11.13,3.87-20.52,11.6-28.15c7.7-7.6,17.14-11.4,28.3-11.4c12.07,0,22.13,4.54,30.17,13.62c2.22,2.49,3.83,4.91,4.84,7.26
			h-8.58c-6.26-9.32-15.12-13.97-26.59-13.97c-8.71,0-16.26,3.3-22.65,9.89c-6.29,6.49-9.43,14.11-9.43,22.85
			c0,8.61,3.21,16.16,9.64,22.65c6.46,6.53,13.99,9.79,22.6,9.79c5.35,0,10.38-1.31,15.08-3.93c4.37-2.49,8.26-5.99,11.65-10.49
			H704.34z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M763.72,704.89v-35.87h-39.55v35.87h-7.67v-76.48h7.67v33.7h39.55v-33.7h7.62v76.48H763.72z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M838.99,676.08c0,2.86-0.2,5.57-0.61,8.12c-0.5,2.99-1.26,5.58-2.27,7.77c-4.44,9.52-12.16,14.28-23.16,14.28
			c-11,0-18.72-4.76-23.16-14.28c-1.01-2.19-1.75-4.77-2.22-7.77c-0.44-2.56-0.66-5.26-0.66-8.12v-47.67h7.67v47.67
			c0,2.49,0.17,4.84,0.5,7.06c0.44,2.83,1.13,5.08,2.07,6.76c3.43,6.26,8.69,9.38,15.79,9.38c7.1,0,12.36-3.13,15.79-9.38
			c0.94-1.68,1.63-3.93,2.07-6.76c0.37-2.22,0.55-4.57,0.55-7.06v-47.67h7.62V676.08z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M881.17,679.36v25.53h-7.67v-25.53l-26.54-50.95h8.48l21.79,42.88l22.4-42.88H908L881.17,679.36z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M915.22,704.89v-76.48h41.82v6.96h-34.15v27.44h33.04v6.96h-33.04v28.2h34.15v6.91H915.22z M944.78,622.05l-8.88-7.57
			l-9.13,7.57h-9.38l14.98-12.92h6.81l14.78,12.92H944.78z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M1024.29,704.89l-45.35-67.25h-0.2v67.25h-7.67v-76.48h10.34l42.68,63.46h0.2v-63.46h7.62v76.48H1024.29z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M1153.89,669.78c-0.84,10.39-5.18,19.09-13.02,26.08c-7.77,6.93-16.9,10.39-27.39,10.39c-11,0-20.55-3.83-28.65-11.5
			c-8.24-7.8-12.36-17.17-12.36-28.1c0-11.03,4.09-20.41,12.26-28.15c8-7.6,17.56-11.4,28.65-11.4c12.61,0,23.02,4.67,31.23,14.02
			c2.22,2.49,3.87,5.04,4.94,7.67h-8.88c-2.62-4.54-6.51-8.17-11.65-10.9c-4.94-2.59-10.12-3.88-15.54-3.88
			c-9.11,0-16.93,3.18-23.46,9.54c-6.59,6.39-9.89,14.12-9.89,23.21c0,9.15,3.33,16.88,9.99,23.21c6.59,6.22,14.48,9.33,23.66,9.33
			c6.89,0,13.3-2.1,19.22-6.31c6.12-4.34,10.02-9.77,11.7-16.29h-42.68v-6.91H1153.89z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M1166.25,704.89v-76.48h7.67v76.48H1166.25z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M1248.68,704.89l-11.1-25.73h-35.52l-11.15,25.73h-7.97l32.64-76.48h8.58l32.84,76.48H1248.68z M1219.93,637.14
			l-14.98,35.11h29.66L1219.93,637.14z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M1295.2,704.89v-76.48h7.67v69.57h31.58v6.91H1295.2z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M1401.44,704.89l-11.1-25.73h-35.52l-11.15,25.73h-7.97l32.64-76.48h8.58l32.84,76.48H1401.44z M1381.77,622.05
			l-8.88-7.57l-9.08,7.57h-9.38l14.98-12.92h6.81l14.78,12.92H1381.77z M1372.69,637.14l-14.98,35.11h29.66L1372.69,637.14z
			 M1368.9,724.31v-13.07h7.67v13.07H1368.9z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M1459.66,669.68c-2.62,1.78-5.79,3.01-9.48,3.68c-1.55,0.27-3.25,0.47-5.1,0.61c-1.48,0.1-3.2,0.15-5.15,0.15h-13.62
			v30.77h-7.67v-76.48h21.29c1.95,0,3.67,0.05,5.15,0.15c1.85,0.13,3.55,0.34,5.1,0.61c3.7,0.71,6.86,1.95,9.48,3.73
			c6.32,4.31,9.48,10.43,9.48,18.36C1469.14,659.2,1465.98,665.34,1459.66,669.68z M1452.65,637.34c-1.92-0.94-4.05-1.53-6.41-1.77
			l-3.28-0.2h-16.65v31.78h16.65l3.28-0.2c2.35-0.23,4.49-0.81,6.41-1.71c5.92-2.83,8.88-7.48,8.88-13.97
			C1461.53,644.77,1458.57,640.13,1452.65,637.34z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M1526.5,635.37v69.52h-7.67v-69.52h-17.56v-6.96h42.68v6.96H1526.5z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M1597.18,669.32c-4.27,4.27-9.65,6.63-16.14,7.06l21.59,28.5H1593l-25.93-34.61l8.27-0.05c3.46-0.1,6.19-0.47,8.17-1.11
			c8.41-2.56,12.61-7.95,12.61-16.19c0-6.76-2.79-11.65-8.38-14.68c-2.19-1.18-4.59-1.98-7.21-2.42c-1.95-0.3-4.51-0.45-7.67-0.45
			h-13.32v69.52h-7.67v-76.48h19.42c1.72,0,3.26,0.03,4.64,0.1c1.78,0.1,3.41,0.24,4.89,0.4c3.77,0.47,6.86,1.28,9.28,2.42
			c9.08,4.37,13.62,11.64,13.62,21.79C1603.74,659.55,1601.55,664.95,1597.18,669.32z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M1623.01,624.07l-20.48-11.05l3.33-6.21l20.53,11.3L1623.01,624.07z M1615.24,704.89v-76.48h7.62v76.48H1615.24z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M1691.62,704.89l-45.35-67.25h-0.2v67.25h-7.67v-76.48h10.34l42.68,63.46h0.2v-63.46h7.62v76.48H1691.62z"
                />
                <path
                  id="Logo"
                  className="draw-mini"
                  d="M1762.15,704.89v-35.87h-39.55v35.87h-7.67v-76.48h7.67v33.7h39.55v-33.7h7.62v76.48H1762.15z"
                />
              </g>
            </g>
          </svg>
  );
}
